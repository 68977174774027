import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface Pfp {
  customerName: string,
  typeOfId: string,
  customerId: string,
  contactNumber: string,
  email: string,
  branchId: string,
  serviceTypeId: string,
  remarks: string,
  fileName: string,
}

export interface PfpReport {
  customerName: string,
  email: string,
  phone: string,
  status: string,
  startDate: string,
  joinDate: string,
  generatedDate: string,
  faName: string,
}


@Module
export default class PfpModule extends VuexModule {
  errors = {};
  pfps = [] as Pfp[];
  pfp = {} as Pfp;
  pfpReport = [] as PfpReport[];

  get getPfpsData() {
    return this.pfps;
  }

  get getPfpData() {
    return this.pfp;
  }

  get getPfpReportData() {
    return this.pfpReport;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentPfpData(): Pfp {
    return this.pfp;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PFPS](data) {
    this.pfps = data
  }

  @Mutation
  [Mutations.SET_PFP](pfp) {
    this.pfp = pfp;
  }

  @Mutation
  [Mutations.SET_PFP_REPORT](data) {
    this.pfpReport = data
  }

  @Action
  [Actions.GET_PFPS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("pfp-logs/index-by-user/" + user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PFPS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PFP_REPORT]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("pfp-logs")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PFP_REPORT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PFP](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ra-form/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PFP, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PFP](pfp) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("pfp-logs", pfp)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PFP, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });

  }

  // @Action
  // [Actions.UPDATE_PFP](pfp) {
  //   ApiService.setFormDataHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.patch("pfp/" + pfp.id, pfp)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_PFP, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }
}
