import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Ccris {
  url: string,
}


@Module
export default class CbsLookupModule extends VuexModule {
  ccris = {} as Ccris;

  get getCcrisData() {
    return this.ccris;
  }

  @Mutation
  [Mutations.SET_CCRIS](data) {
    this.ccris = data
  }

  @Action
  [Actions.GET_CCRIS](customer_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ccris-report/" + customer_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CCRIS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_CCRIS](file) {
    return new Promise<void>((resolve, reject) => {
      ApiService.postFormData("ccrises/uploadCcris",file)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CCRIS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_CCRIS2](file) {
    return new Promise<void>((resolve, reject) => {
      ApiService.postFormData("ccrises/uploadCcrisCustomer",file)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CCRIS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DOC_PARSER_CCRIS](fin_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ccrises/getCcrisByFinId/" + fin_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CCRIS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

}
