import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

// export interface Customer {
//   id: number,
//   birthdate: string,
//   company_id: 0,
//   detail: Record<string, never>,
//   email: string,
//   facilities: [],
//   gender: string,
//   media: [],
//   name: string,
//   nric: string,
//   phone: string,
// }
export interface Assignment {
  customer_id: number;
  fa_id: number;
  method_id: number;
  service_id: number;
  appointment_date: string;
  start_time: string;
  end_time: string;
}

@Module
export default class CustomerModule extends VuexModule {
  errors = {};
  customers = [] as any[];
  customer = {} as any;
  assignments = [] as any[];
  users = [] as any[];
  assignment = {} as Assignment;
  reverseMortgage = {};
  property = {};
  response_raw = {} as any;
  rmCertificate = {} as any;
  user_raw = {} as any;
  myknp_checklist = {} as any;
  myknpPropertyInfo = {} as any;
  customerBasicProfile = {} as any;
  myknpPreAssessmentInfo = {} as any;
  myknpCustomerFeedback = {} as any;
  media = [] as any[];
  myknpCertificate = {} as any;
  appointments = [] as any[];
  appointment = {} as any;
  response = {} as any;
  family = {} as any;
  will = {} as any;
  ffs = {} as any;

  get getReverseMorgage() {
    return this.reverseMortgage;
  }

  get getPropertyData() {
    return this.property;
  }

  get getCustomersData() {
    return this.customers;
  }

  get getCustomerData() {
    return this.customer;
  }

  get getAssignmentsData() {
    return this.assignments;
  }

  get getAppointmentData() {
    return this.appointment;
  }

  get getUsersData() {
    return this.users;
  }

  get getResponseRawData() {
    return this.response_raw;
  }

  get getRMCertificateData() {
    return this.rmCertificate;
  }

  get getCustomerBasicProfileData() {
    return this.customerBasicProfile;
  }

  get getMediaData() {
    return this.media;
  }

  get getAppointnments() {
    return this.appointments;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentCustomerData(): any {
    return this.customer;
  }

  get getUserRawData() {
    return this.user_raw;
  }

  get getMyKNPChecklistData() {
    return this.myknp_checklist;
  }

  get getMyKNPPropertyInfoData() {
    return this.myknpPropertyInfo;
  }

  get getMyKNPPreAssessmentData() {
    return this.myknpPreAssessmentInfo;
  }

  get getMyKNPCustomerFeedbackData() {
    return this.myknpCustomerFeedback;
  }

  get getMyKNPCertificateData() {
    return this.myknpCertificate;
  }

  get getFamilyProfileData() {
    return this.family;
  }

  get getWillProfileData() {
    return this.will;
  }

  get getFactFindingSheetProfileData() {
    return this.ffs;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](data) {
    this.customers = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER](customer) {
    this.customer = customer;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_ASSIGNMENTS](data) {
    this.assignments = data;
  }

  @Mutation
  [Mutations.SET_ASSIGNMENT](data) {
    this.assignment = data;
  }

  @Mutation
  [Mutations.SET_APPOINTMENT](data) {
    this.appointment = data;
  }

  @Mutation
  [Mutations.SET_REVERSE_MORTGAGE](data) {
    this.reverseMortgage = data;
  }

  @Mutation
  [Mutations.SET_PROPERTY](data) {
    this.property = data;
  }

  [Mutations.SET_RAW_APPOINTMENT_RESPONSE](data) {
    this.response_raw = data;
  }

  @Mutation
  [Mutations.SET_RM_CERTIFICATE](data) {
    this.rmCertificate = data;
  }

  @Mutation
  [Mutations.SET_USER_RAW](data) {
    this.user_raw = data;
  }

  @Mutation
  [Mutations.SET_MYKNP_CHECKLIST](data) {
    this.myknp_checklist = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_BASIC_PROFILE](data) {
    this.customerBasicProfile = data;
  }

  @Mutation
  [Mutations.SET_MYKNP_PROPERTY_INFO](data) {
    this.myknpPropertyInfo = data;
  }

  @Mutation
  [Mutations.SET_MYKNP_PRE_ASSESSMENT_INFO](data) {
    this.myknpPreAssessmentInfo = data;
  }

  @Mutation
  [Mutations.SET_MYKNP_CUSTOMER_FEEDBACK](data) {
    this.myknpCustomerFeedback = data;
  }

  @Mutation
  [Mutations.SET_MEDIA](data) {
    this.media = data;
  }

  @Mutation
  [Mutations.SET_MYKNP_CERTIFICATE](data) {
    this.myknpCertificate = data;
  }

  @Mutation
  [Mutations.SET_APPOINTMENTS](data) {
    this.appointments = data;
  }

  @Mutation
  [Mutations.SET_RAW](data) {
    this.response = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_FAMILY_PROFILE](data) {
    this.family = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_WILL_PROFILE](data) {
    this.will = data;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_FFS_PROFILE](data) {
    this.ffs = data;
  }

  @Action
  [Actions.GET_CUSTOMERS]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/get-all-users") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMERS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CUSTOMER](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ASSIGNMENTS](fa_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slots/get-slots-by-fa-id/" + fa_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASSIGNMENTS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_REVERSE_MORTGAGE](customer) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("user-raws/get-reverse-mortgage-by-user/" + customer) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REVERSE_MORTGAGE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PROPERTY](customer) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("user-raws/get-property-by-user/" + customer) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROPERTY, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RM_CERTIFICATE](customer_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "reverse-mortgage-certs/get-cert-by-user-id/" + customer_id
      ) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RM_CERTIFICATE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USERS](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_APPOINTMENTS](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slots/get-appointments-by-user-id/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPOINTMENTS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_APPOINTMENT](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slots/get-appointment-basic-by-id/" + id) // 🚧
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPOINTMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_ASSIGNMENT](assignment) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("slots", assignment) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ASSIGNMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_CUSTOMER](customer) {
    ApiService.setFormDataHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("users/" + customer.id, customer) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_CUSTOMER_RAW](payload) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.post("user-raws", payload) // ✅
        .then(({ data }) => {
          ApiService.setHeader();
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_CUSTOMER_RAW](payload) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put("user-raws/" + payload.id, payload) // ✅
        .then(({ data }) => {
          // ApiService.setHeader();
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_RAW_APPOINTMENT](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/user-raws/store-raws", data) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RAW_APPOINTMENT_RESPONSE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_APPOINTMENT](appointment) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/slots/store-appointment", appointment) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPOINTMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CUSTOMER_BY_NRIC](nric) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/get-user-by-nric/" + nric) // 🚧
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_RAW](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/user-raws/store-raws", data) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RAW, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_RAW](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "/user-raws/get-user-raw/" + data.type + "/" + data.user_id
      ) // 🚧
        .then(({ data }) => {
          if (data.success == true) {
            if (data.userRaw.type == "myknp_checklist") {
              this.context.commit(
                Mutations.SET_MYKNP_CHECKLIST,
                data.userRaw.raw
              );
            } else if (data.userRaw.type == "myknp_property_info") {
              this.context.commit(
                Mutations.SET_MYKNP_PROPERTY_INFO,
                data.userRaw.raw
              );
            } else if (data.userRaw.type == "myknp_pre_assessment") {
              this.context.commit(
                Mutations.SET_MYKNP_PRE_ASSESSMENT_INFO,
                data.userRaw.raw
              );
            } else if (data.userRaw.type == "myknp_customer_feedback") {
              this.context.commit(
                Mutations.SET_MYKNP_CUSTOMER_FEEDBACK,
                data.userRaw.raw
              );
            }
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.message]);
          }
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.PRE_REGISTER_CUSTOMER](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/users/pre-register-customer", data) // ✅
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_BASIC_PROFILE](user_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/users/basic-profile-by-id/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_BASIC_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_FAMILY_PROFILE](user_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/users/family-profile-by-id/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_FAMILY_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_WILL_PROFILE](user_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/users/will-profile-by-id/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_WILL_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_FFS_PROFILE](user_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/users/ffs-profile-by-id/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER_FFS_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_DOC](params) {
    ApiService.setFormDataHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.postFormData("/user-raws/upload-doc-by-user-id", params) // 🚧
        .then(({ data }) => {
          ApiService.setHeader();
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_DOC_BY_USER_TAG](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "/user-raws/get-media-by-user-tag/" + data.user_id + "/" + data.tag
      ) // 🚧
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MEDIA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_MYKNP_CERTIFICATE](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("myknp-certs/generate-cert", data) // 🚧
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MYKNP_CERTIFICATE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.PRE_STORE_APPOINTMENT](appointment) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/slots/pre-store-appointment", appointment) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPOINTMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_MYKNP_CERTIFICATE](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("myknp-certs/get-my-cert/" + user_id) // 🚧
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MYKNP_CERTIFICATE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
