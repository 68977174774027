import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface SlotSet {
  id: number,
  name: string,
  raw: string,
  is_monday: number,
  is_tuesday: number,
  is_wednesday: number,
  is_thursday: number,
  is_friday: number,
  is_saturday: number,
  is_sunday: number,

}


@Module
export default class SlotSetModule extends VuexModule {
  errors = {};
  slotSets = [] as SlotSet[];
  slotSet = {} as SlotSet;

  get getSlotSetsData() {
    return this.slotSets;
  }

  get getSlotSetData() {
    return this.slotSet;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentSlotSetData(): SlotSet {
    return this.slotSet;
  }

  /**
   * Get authentification errors
   * @returns array
   */


  @Mutation
  [Mutations.SET_SLOT_SET](slotSet) {
    this.slotSet = slotSet;
  }

  @Mutation
  [Mutations.SET_SLOT_SETS](slotSets) {
    this.slotSets = slotSets;
  }

  @Action
  [Actions.GET_SLOT_SETS]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slot-sets") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SLOT_SETS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SLOT_SET](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slot-sets/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SLOT_SET, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
