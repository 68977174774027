import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AdminModule from "@/store/modules/AdminModule";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import CbsLookup from "@/store/modules/CbsLookup";
import Ccris from "@/store/modules/Ccris";
import ConfigModule from "@/store/modules/ConfigModule";
import CustomerModule from "@/store/modules/CustomerModule";
import DMPModule from "@/store/modules/DMPModule";
import FinancialResolutionModule from "@/store/modules/FinancialResolutionModule";
import FinancialSkillsModule from "@/store/modules/FinancialSkillsModule";
import FinancialStatementModule from "@/store/modules/FinancialStatementModule";
import GlobalModule from "@/store/modules/GlobalModule";
import NotificationModule from "@/store/modules/NotificationModule";
import Oqf from "@/store/modules/Oqf";
import OrganisationModule from "@/store/modules/OrganisationModule";
import PfpModule from "@/store/modules/PfpModule";
import PowerPortalModule from "@/store/modules/PowerPortalModule";
import RaFormModule from "@/store/modules/RaFormModule";
import SlotModule from "@/store/modules/SlotModule";
import SlotSetModule from "@/store/modules/SlotSetModule";
import financialPlanningModule from "@/store/modules/FinancialPlanningModule";

config.rawError = true;

const store = createStore({
  modules: {
    AdminModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    CbsLookup,
    Ccris,
    ConfigModule,
    CustomerModule,
    DMPModule,
    FinancialResolutionModule,
    FinancialSkillsModule,
    FinancialStatementModule,
    GlobalModule,
    NotificationModule,
    Oqf,
    OrganisationModule,
    PfpModule,
    PowerPortalModule,
    RaFormModule,
    SlotModule,
    SlotSetModule,
    financialPlanningModule,

  },

});

export default store;
