import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface Oqf {
  customerName: string,
  typeOfId: string,
  customerId: string,
  contactNumber: string,
  email: string,
  branchId: string,
  serviceTypeId: string,
  remarks: string,
  fileName: string,
}


@Module
export default class OqfModule extends VuexModule {
  errors = {};
  oqfs = [] as Oqf[];
  oqf = {} as Oqf;

  get getOqfsData() {
    return this.oqfs;
  }

  get getOqfData() {
    return this.oqf;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentOqfData(): Oqf {
    return this.oqf;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_OQFS](data) {
    this.oqfs = data
  }

  @Mutation
  [Mutations.SET_OQF](oqf) {
    this.oqf = oqf;
  }

  @Action
  [Actions.GET_OQFS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("oqf/index-by-user/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_OQFS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  // @Action
  // [Actions.GET_OQF](id) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.get("oqf/" + id)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_OQF, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.STORE_OQF](oqf) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("oqf", oqf) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_OQF, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });

  }

  // @Action
  // [Actions.UPDATE_OQF](oqf) {
  //   ApiService.setFormDataHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.patch("oqf/" + oqf.id, oqf)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_OQF, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }
}
