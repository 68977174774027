import ApiService from "@/core/services/ApiService";
import OcrService from "@/core/services/OcrService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";



@Module
export default class GlobalModule extends VuexModule {
  errors = {};
  // customers = [] as Customer[];
  data = {} as any;
  token = '' as string;

  // get getCustomersData() {
  //   return this.customers;
  // }

  get getGlobalData() {
    return this.data;
  }

  get accessToken() {
    return this.token;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  // [Mutations.SET_CUSTOMERS](data) {
  //   this.customers = data
  // }

  @Mutation
  [Mutations.SET_GLOBAL_DATA](data) {
    this.data = data;
  }

  @Mutation
  [Mutations.SET_ACCESS_TOKEN](data) {
    OcrService.saveToken(data.access_token);
    this.token = data.access_token;
  }

  // @Action
  // [Actions.GET_CUSTOMERS](translation) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.get("customers")
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_CUSTOMERS, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.GET_ACCESS_TOKEN](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("fas/get-access-token")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ACCESS_TOKEN, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
    [Actions.GET_OCR](data) {
        // const documentAiUri = 'https://us-documentai.googleapis.com/v1/projects/762909992879/locations/us/processors/cdf71ba6ba36e6bd:process';
        // const documentAiUri = 'https://us-documentai.googleapis.com/v1/projects/868171206536/locations/us/processors/e1ce9b87638b28c8:process'
        const documentAiUri = 'https://us-documentai.googleapis.com/v1beta2/projects/akpk-fas-346618/locations/us/documents:process';
        return new Promise<void>((resolve, reject) => {
            ApiService.setGoogleHeader();
            ApiService.post(documentAiUri,data)
                .then(({data}) => {
                    ApiService.setHeader();
                    // this.context.commit(Mutations.SET_OCR, data.document.entities);
                    resolve();
                })
                .catch(({response}) => {
                    // this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject();
                });
        });
    }

  // @Action
  // [Actions.STORE_CUSTOMER](customer) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("customers", customer)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_CUSTOMER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });

  // }

  // @Action
  // [Actions.UPDATE_CUSTOMER](customer) {
  //   ApiService.setFormDataHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.put("customers/" + customer.id, customer)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_CUSTOMER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }
}
