import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Organisation {
  id: number,
  name: string,
  parent_id: number,
  address: string,
  lattitude: string,
  longitude: string,
  org_type: string,
  raw: any[]
}


@Module
export default class OrganisationModule extends VuexModule {
  organisation = {} as Organisation;
  branches = [] as any[];
  advisoryOffices = [] as any[];
  allAdvisoryOffices = [] as any[];
  advisors = [] as any[];


  get getOrganisationData() {
    return this.organisation;
  }

  get getBranchesData() {
    return this.branches;
  }

  get getadvisoryOfficessData() {
    return this.advisoryOffices;
  }

  get getAllAdvisoryOfficessData() {
    return this.allAdvisoryOffices;
  }

  get getAdvisorsData() {
    return this.advisors;
  }

  @Mutation
  [Mutations.SET_ORGANISATION](data) {
    this.organisation = data
  }

  @Mutation
  [Mutations.SET_BRANCHES](data) {
    this.branches = data
  }

  @Mutation
  [Mutations.SET_FA_OFFICES](data) {
    this.allAdvisoryOffices = data
  }

  @Mutation
  [Mutations.SET_ADVISORY_OFFICES](data) {
    this.advisoryOffices = data
  }

  @Mutation
  [Mutations.SET_ADVISOR](data) {
    this.advisors = data
  }

  @Action
  [Actions.GET_ORGANISATION](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("organisations/" + id)  // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORGANISATION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BRANCHES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("organisations/index-branch") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BRANCHES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FA_OFFICES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("organisations/index-fa-offices") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FA_OFFICES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ADVISORY_OFFICES](org_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("organisations/list-fa-office/" + org_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADVISORY_OFFICES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ADVISOR](orgID) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/index-user-by-org/"+orgID) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ADVISOR, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_ORGANISATION](organisation) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("organisations", organisation) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORGANISATION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_ORGANISATION](organisation) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.patch("organisations/" + organisation.id, organisation) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORGANISATION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
