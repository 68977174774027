import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Notification {
    id: string,
    data: [],
    timeLast: string
}

@Module
export default class NotificationModule extends VuexModule {
    errors = {};
    notifications = [] as Notification[];
    notification = {} as Notification;

    get getNotifications() {
        return this.notifications;
    }

    get getNotification() {
        return this.notification;
    }

    get getCurrentNotificationData(): Notification {
        return this.notification;
    }

    /**
     * Get authentification errors
     * @returns array
     */

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_NOTIFICATIONS](data) {
        this.notifications = data
    }

    @Mutation
    [Mutations.SET_NOTIFICATION](notification) {
        this.notification = notification;
    }

    @Action
    [Actions.GET_UNREAD_NOTIFICATIONS]() {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.get("notifications/unread") // ✅
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_NOTIFICATIONS, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject();
                });
        });
    }

    @Action
    [Actions.UPDATE_NOTIFICAITON_AS_READ](id) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
          ApiService.post("notifications/mark-as-read", id) // ✅
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_NOTIFICATIONS, data);
                    resolve();
                })
                .catch(({ response }) => {
                    console.log(response);
                    this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject();
                });
        });
    }
}
