import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface FinancialStatement {
  id: number,
  financial_statement_type_id: number,
  raw: Record<string, never>,
}


@Module
export default class FinancialStatementModule extends VuexModule {
  errors = {};
  financialStatements = [] as FinancialStatement[];
  financialStatement = {} as FinancialStatement;

  get getFinancialStatementsData() {
    return this.financialStatements;
  }

  get getFinancialStatementData() {
    return this.financialStatement;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentFinancialStatementData(): FinancialStatement {
    return this.financialStatement;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_FINANCIAL_STATEMENTS](data) {
    this.financialStatements = data
  }

  @Mutation
  [Mutations.SET_FINANCIAL_STATEMENT](financialStatement) {
    this.financialStatement = financialStatement;
  }

  @Action
  [Actions.GET_FINANCIAL_STATEMENTS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-statements/index-by-user-latest/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_STATEMENTS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FINANCIAL_STATEMENT](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-statements/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_STATEMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_FINANCIAL_STATEMENT](financialStatement) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("financial-statements", financialStatement) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_STATEMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });

  }

  @Action
  [Actions.UPDATE_FINANCIAL_STATEMENT](financialStatement) {
    ApiService.setFormDataHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("financial-statements/" + financialStatement.id, financialStatement) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_STATEMENT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
