import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface FinancialPlanning {
  id: number;
  user_id: number;
  type: string;
  raw: string;
}

@Module
export default class FinancialPlanningModule extends VuexModule {
  willPlannings = [] as FinancialPlanning[];
  willPlanning = {} as FinancialPlanning;

  taxPlannings = [] as FinancialPlanning[];
  taxPlanning = {} as FinancialPlanning;
  taxLimits = {} as any;

  riskPlanning = {} as FinancialPlanning;

  get getWillPlanningsData() {
    return this.willPlannings;
  }

  get getWillPlanningData() {
    return this.willPlanning;
  }

  get getTaxPlanningsData() {
    return this.taxPlannings;
  }

  get getTaxPlanningData() {
    return this.taxPlanning;
  }

  get getTaxLimitsData() {
    return this.taxLimits;
  }

  get getRiskPlanningData() {
    return this.riskPlanning;
  }

  // get willPlanningPersonalData() {
  //   return this.willPlanning.personal;
  // }

  @Mutation
  [Mutations.SET_WILL_PLANNINGS](data) {
    this.willPlannings = data;
  }

  @Mutation
  [Mutations.SET_WILL_PLANNING](data) {
    this.willPlanning = data;
  }

  @Mutation
  [Mutations.SET_TAX_PLANNINGS](data) {
    this.taxPlannings = data;
  }

  @Mutation
  [Mutations.SET_TAX_PLANNING](data) {
    this.taxPlanning = data;
  }

  @Mutation
  [Mutations.SET_TAX_LIMITS](data) {
    this.taxLimits = data;
  }

  @Mutation
  [Mutations.SET_RISK_PLANNING](data) {
    this.riskPlanning = data;
  }

  @Action
  [Actions.STORE_WILL_PLANNING](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("financial-plannings", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WILL_PLANNING, payload);
          resolve();
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_WILL_PLANNINGS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-plannings/get-will-plannings/" + user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WILL_PLANNINGS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_WILL_PLANNING](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-plannings/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WILL_PLANNING, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TAX_LIMITS]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("tax-settings")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_LIMITS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_TAX_PLANNING](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("financial-plannings", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_PLANNING, payload);
          resolve();
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TAX_PLANNINGS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-plannings/get-tax-plannings/" + user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_PLANNINGS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TAX_PLANNING](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-plannings/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_PLANNING, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_RISK_PLANNING](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      const url = "financial-plannings";
      ApiService.post(url, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RISK_PLANNING, data.data);
          resolve();
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RISK_PLANNING](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-plannings/get-risk-planning-by-user-id/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RISK_PLANNING, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
