import {createI18n} from "vue-i18n/index";

const organisation = {
en: {
  organisation: {
    validations:{
      name: {
        required: 'Please insert name'
      },
      org_type: {
        required: 'Please choose organisation type'
      },
      address: {
        required: 'Please insert your address'
      }
    },
    label: {
      name: 'Name',
      org_type: 'Organisation Type',
      branch: 'Branch',
      address: 'Address',
      latitude: 'Latitude',
      longitude: 'Longitude',
    },
    table: {
      name: 'Name',
      org_type: 'Organisation Type',
      latitude: 'Latitude',
      longitude: 'Longitude',
      branch: 'Branch',
      fa_office: 'Office',
      created_at: 'Created At',
      updated_at: 'Modified At',
      action: 'Actions'
    }
  } 
},
ms: {
  organisation: {
    validations:{
      name: {
        required: 'Sila masukkan nama'
      },
      org_type: {
        required: 'Sila pilih jenis organisasi'
      },
      address: {
        required: 'Sila masukkan alamat'
      }
    },
    label: {
      name: 'Nama',
      org_type: 'Jenis Organisasi',
      branch: 'Cawangan',
      address: 'Alamat',
      latitude: 'Latitud',
      longitude: 'Longitud',
      
    },
    table: {
      name: 'Nama',
      org_type: 'Jenis Organisasi',
      latitude: 'Latitud',
      longitude: 'Longitud',
      branch: 'Cawangan',
      fa_office: 'Pejabat',
      created_at: 'Dicipta',
      updated_at: 'Diubah',
      action: 'Aksi'
    }
  }
}
};

export default organisation;
