import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}
export interface Profile {
  name: string;
  phone: string;
  // surname: string;
  // email: string;
  // password: string;
  // token: string;
}


export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  auth_user = {} as User;
  profile = {} as Profile;
  admin_user = {} as any;
  isAuthenticated = !!JwtService.getToken();
  faLists = {} as any
  faListByOrgId = {} as any
  response_set_org = {} as any;
  userFAS = {} as any;
  resPreSignUp = {} as any;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getProfile(): Profile {
    return this.profile;
  }

  get getUserFASData() {
    return this.userFAS;
  }


  get getFaListData() {
    return this.faLists;
  }

  get getFaListByOrgIdData() {
    return this.faListByOrgId;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getAdminUserData() {
    return this.admin_user;
  }

  get responseSetOrgData() {
    return this.response_set_org;
  }

  get getPreSignUpData(): boolean {
    return this.resPreSignUp;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    // console.log(data)
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];
    JwtService.saveToken(data.user.token);
  }

  @Mutation
  [Mutations.SET_PROFILE](profile) {
    this.profile = profile;
  }

  @Mutation
  [Mutations.SET_AUTH_USER](user) {
    this.auth_user = user;
  }

  @Mutation
  [Mutations.SET_USER_FAS](user) {
    this.userFAS = user;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_FA_LIST](fa_list) {
    this.faLists = fa_list;
  }

  @Mutation
  [Mutations.SET_FA_LIST_BY_ORG_ID](fa_list) {
    this.faListByOrgId = fa_list;
  }

  @Mutation
  [Mutations.SET_ADMIN_USER](admin_user) {
    this.admin_user = admin_user;
  }

  @Mutation
  [Mutations.SET_RESPONSE_UPDATE_ORG](response) {
    this.response_set_org = response;
  }

  @Mutation
  [Mutations.SET_PRE_SIGN_UP](data) {
    this.resPreSignUp = data;
  }



  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials) // ✅
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response?.data?.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("register", credentials) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });

  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params) // ✅
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, [response.data.message]);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CSRF](credentials) {
    return ApiService.get("/sanctum/csrf-cookie/", credentials)  // ❌
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_AUTH_USER](payload) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/profile", payload) // ✅
        .then(({ data }) => {
          // console.log(data)
          this.context.commit(Mutations.SET_PROFILE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ADMIN_USER](payload) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/admin-user", payload)
        .then(({ data }) => {
          // console.log(data)
          this.context.commit(Mutations.SET_ADMIN_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  // @Action
  // [Actions.UPDATE_PROFILE](payload) {

  //   ApiService.setFormDataHeader();

  //   // return new Promise<void>((resolve, reject) => {
  //   //   ApiService.post("user-raws/" + payload.get('id'), payload) // ✅
  //   //     .then(({ data }) => {
  //   //       ApiService.setHeader();
  //   //       this.context.commit(Mutations.SET_AUTH_USER, data);
  //   //       resolve();
  //   //     })
  //   //     .catch(({ response }) => {

  //   //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //   //       reject();
  //   //     });
  //   // });

  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("users/update-profile/" + payload.get('id'), payload) // ✅
  //       .then(({ data }) => {
  //         ApiService.setHeader();
  //         // this.context.commit(Mutations.SET_AUTH_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {

  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.UPDATE_PROFILE](user) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("users/update-profile/" + user.id, user) // ✅
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_USER_FAS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }


  @Action
  [Actions.STORE_PROFILE](payload) {

    ApiService.setFormDataHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.postFormData("user-raws", payload) // ✅
        .then(({ data }) => {
          ApiService.setHeader();
          this.context.commit(Mutations.SET_AUTH_USER, data);
          resolve();
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FA_LIST](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/get-fa-list") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FA_LIST, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FA_LIST_BY_ORG_ID]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("users/get-fa-list-by-org-id") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FA_LIST_BY_ORG_ID, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADMIN_UPDATE_ORGANISATION](payload) {
    console.log(payload);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("users/admin-update-org", payload) // 🟠
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE_UPDATE_ORG, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADMIN_ASSIGN_OFFICE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("users/admin-assign-office", payload) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE_UPDATE_ORG, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADMIN_DELETE_ASSIGNED_AO](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("users/admin-delete-assigned-ao/" + id) // 🟠
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE_UPDATE_ORG, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADMIN_GET_USER_FAS](user_id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/users/get-user-fas", user_id) // ✅
        .then(({ data }) => {
          // console.log(data)
          this.context.commit(Mutations.SET_USER_FAS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ADMIN_UPDATE_USER_FAS](user) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.patch("admin/users/update_user_fas/" + user.id, user) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_FAS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.TWOFA_LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("2fa/verify", credentials) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.SET_ERROR, [response?.data?.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PRE_SIGN_UP](value) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("pre-signup", value) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRE_SIGN_UP, data);
          resolve();
        })
        .catch(({ response }) => {
          if(response){
            this.context.commit(Mutations.SET_ERROR, [response?.data?.message]);
            reject();
          }else{
            resolve();
          }
        });
    });
  }
}
