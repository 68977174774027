import { createI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import general from "./translations/general";
import oraganisation from "./translations/organisation";
import user from "./translations/user";


const messages = {
  en: {
    ...user.en,
    ...general.en,
    ...oraganisation.en,
    account: "Account",
    accountOverview: "Overview",
    activity: "Activity",
    addSubscription: "Add Subscription",
    amortisation: "Amortisation",
    apps: "Apps",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    calendarApp: "Calendar",
    campaigns: "Campaigns",
    cashflows: "Cash Flows",
    ccriss: "CCRIS",
    changelog: "Changelog",
    chat: "Chat",
    components: "Components",
    connections: "Connections",
    craft: "Crafted",
    createAPIKey: "Create API Key",
    createAccount: "Create Account",
    createApp: "Create App",
    creditCard: "Credit Card",
    customerDetails: "Customers Details",
    customers: "Customers",
    customersListing: "Customers Listing",
    dailyCashFlow: "Daily Cash Flow",
    dashboard: "Dashboard",
    debtManagementProgramme: "Debt Management Programme",
    digitalFeedback: "Digital Feedback",
    dmpCalculators: "DMP Calculators",
    docsAndComponents: "Docs & Components",
    documentation: "Documentation",
    documents: "Documents",
    drawerChat: "Drawer Chat",
    error404: "Error 404",
    error500: "Error 500",
    exampleLink: "Example link",
    factFindingSheet: "Fact Finding Sheet",
    financialCalculators: "Financial Calculator",
    financialEducation: "Financial Education",
    financialEducationList: "Financial Education List",
    financialHealthCheck: "Financial Health Check",
    financialSimulations: "Financial Simulations",
    financialStatement: "Financial Statement",
    financialStatements: "Financial Statements",
    forms: "Forms",
    futureRetirement: "Future Retirement",
    general: "General",
    getStarted: "Getting Started",
    gettingStarted: "Getting Started",
    groupChat: "Group Chat",
    helpDeskForm: "Help Desk",
    hirePurchase: "Hire Purchase",
    horizontal: "Horizontal",
    householdCashFlow: "Cash Flow Statement",
    housingLoan: "Housing Loan",
    incomeStatement: "Income Statement",
    investment: "Investment",
    investmentPlanning: "Investment Planning",
    inviteFriends: "Invite Friends",
    layoutBuilder: "Layout builder",
    listOfCoordinator: "List Of Coordinator",
    megaMenu: "Mega Menu",
    modals: "Modals",
    netWorthStatement: "Net Worth Statement",
    newAddress: "New Address",
    newCard: "New Card",
    newTarget: "New Target",
    passwordReset: "Password Reset",
    personalLoan: "Personal Loan",
    privateChat: "Private Chat",
    profile: "Profile",
    profileOverview: "Overview",
    profilingQuestionnaire: "Profiling Questionnaire",
    projects: "Projects",
    resources: "Resources",
    retirementPlanning: "Retirement Planning",
    retirementSimulation: "Retirement Simulation",
    sales: "Sales",
    settings: "Settings",
    shareAndEarn: "Share & Earn",
    signIn: "Sign-in",
    signUp: "Sign-up",
    statementCashFlow: "Statement Cash Flow",
    stockCard: "Stock Card",
    subscriptionList: "Subscription List",
    subscriptions: "Subscriptions",
    summaryStock: "Summary Stock",
    taxPlanning: "Tax Planning",
    twoFactorAuth: "Two Factor Auth",
    upgradePlan: "Upgrade Plan",
    vertical: "Vertical",
    viewSubscription: "View Subscription",
    viewUsers: "View Users",
    widgets: "Widgets",
    widgetsCharts: "Charts",
    widgetsFeeds: "Feeds",
    widgetsLists: "Lists",
    widgetsMixed: "Mixed",
    widgetsStatistics: "Statistics",
    widgetsTables: "Tables",
    willPlanning: "Will Planning",
    wizards: "Wizards",
    riskPlanning: "Risk Planning",
  },
  ms: {
    ...user.ms,
    ...general.ms,
    ...oraganisation.ms

  }
};

const numberFormats = {
  "en-MY": {
    currency: {
      style: "currency",
      currency: "MYR",
      currencyDisplay: "symbol",
    },
    number: {
      style: "decimal",
    },
  },
  "ms-MY": {
    currency: {
      // style: "currency",
      // currency: "MYR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number: {
      style: "decimal",
    },
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
  numberFormats
});

export default i18n;
