
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class FinancialSkillsModule extends VuexModule {

    errors = {};
    enrollmentDetails = {};

    get getEnrollmentDetailsData() {
        return this.enrollmentDetails;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_ENROLLMENT_DETAILS](data) {
        this.enrollmentDetails = data
    }

    @Action
    [Actions.GET_ENROLLMENT_DETAILS](user_id) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.get("power-portal/user-enrollment-details/" + user_id)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ENROLLMENT_DETAILS, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject();
                });
        });
    }
}