import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface FinancialResolution {
  id: number,
  raw: Record<string, never>,
}



@Module
export default class FinancialResolutionModule extends VuexModule {
  errors = {};
  financialResolutions = [] as FinancialResolution[];
  financialResolution = {} as FinancialResolution;
  creditorProfiling = {}

  get getFinancialResolutionsData() {
    return this.financialResolutions;
  }

  get getFinancialResolutionData() {
    return this.financialResolution;
  }

  get getCreditorProfilingData() {
    return this.creditorProfiling;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentFinancialResolutionData(): FinancialResolution {
    return this.financialResolution;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_FINANCIAL_RESOLUTIONS](data) {
    this.financialResolutions = data
  }

  @Mutation
  [Mutations.SET_FINANCIAL_RESOLUTION](data) {
    this.financialResolution = data;
  }

  @Mutation
  [Mutations.SET_CREDITOR_PROFILING](creditorProfiling) {
    this.creditorProfiling = creditorProfiling;
  }

  @Action
  [Actions.GET_FINANCIAL_RESOLUTIONS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-resolutions/index-by-user-latest/" + user_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_RESOLUTIONS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FINANCIAL_RESOLUTION](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("financial-resolutions/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_RESOLUTION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }



  @Action
  [Actions.GET_CREDITOR_PROFILING](financialResolution) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("financial-resolutions/creditor-profiling", financialResolution) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CREDITOR_PROFILING, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_FINANCIAL_RESOLUTION](financialResolution) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("financial-resolutions", financialResolution) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_RESOLUTION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_FINANCIAL_RESOLUTION](financialResolution) {
    ApiService.setFormDataHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.patch("financial-resolutions/" + financialResolution.id, financialResolution) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FINANCIAL_RESOLUTION, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
}
