import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface RaForm {
  customerName: string,
  typeOfId: string,
  customerId: string,
  contactNumber: string,
  email: string,
  branchId: string,
  serviceTypeId: string,
  remarks: string,
  fileName: string,
}

export interface responseRaForm {
  success: boolean,
  message: string
}


@Module
export default class RaFormModule extends VuexModule {
  errors = {};
  raForms = [] as RaForm[];
  raForm = {} as RaForm;
  responseRaForm = {} as responseRaForm;

  get getRaFormsData() {
    return this.raForms;
  }

  get getRaFormData() {
    return this.raForm;
  }

  get getResponseRaFormData(){
    return this.responseRaForm;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentRaFormData(): RaForm {
    return this.raForm;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_RA_FORMS](data) {
    this.raForms = data
  }

  @Mutation
  [Mutations.SET_RA_FORM](raForm) {
    this.raForm = raForm;
  }

  @Mutation
  [Mutations.SET_RESPONSE_RA_FORM](responseRaForm) {
    this.responseRaForm = responseRaForm;
  }

  @Action
  [Actions.GET_RA_FORMS](user_id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ra-form/index-by-user/" + user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RA_FORMS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RA_FORM](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ra-form/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RA_FORM, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_RA_FORM](raForm) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ra-form", raForm)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RA_FORM, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });

  }

  @Action
  [Actions.UPLOAD_RA_FORM](payload) {
    ApiService.setFormDataHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.postFormData("ra-form/upload-ra-form", payload)
        .then(({ data }) => {
          ApiService.setHeader();
          this.context.commit(Mutations.SET_RESPONSE_RA_FORM, data);
          resolve();
        })
        .catch(({ response }) => {

          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_RA_FORM_DOCUMENT](id) {
    ApiService.setFormDataHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.delete("ra-form/document/" + id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE_RA_FORM, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
    
  }
  
}
