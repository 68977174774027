import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";


export interface Slot {
  user_id: number,
  fa_id: number,
  method_id: number,
  service_id: number,
  contactNumber: string,
  appointment_date: string,
  start_time: string,
  end_time: string,
  slot_type: string,
  raw: string,
}


@Module
export default class SlotModule extends VuexModule {
  errors = {};
  slots = [] as Slot[];
  slotsHistory = [] as any;
  slot = {} as Slot;
  appDatesResponse = {} as any;
  closeAppDatesResponse = {} as any;
  orgAppDatesData = [] as any;

  get getSlotsData() {
    return this.slots;
  }

  get getSlotData() {
    return this.slot;
  }

  get getAppDatesResponse() {
    return this.appDatesResponse;
  }

  get getCloseAppDatesResponse() {
    return this.closeAppDatesResponse;
  }

  get getOrgAppDateData() {
    return this.orgAppDatesData;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentSlotData(): Slot {
    return this.slot;
  }

  get getAppointmentsHistoryData() {
    return this.slotsHistory;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  // @Mutation
  // [Mutations.SET_ERROR](error) {
  //   this.errors = error;
  // }

  // @Mutation
  // [Mutations.SET_SLOTS](data) {
  //   this.slots = data
  // }

  @Mutation
  [Mutations.SET_SLOT](slot) {
    this.slot = slot;
  }

  @Mutation
  [Mutations.SET_APP_DATES](response) {
    this.appDatesResponse = response;
  }

  @Mutation
  [Mutations.SET_ORG_APP_DATE](data) {
    this.orgAppDatesData = data;
  }


  @Mutation
  [Mutations.SET_CLOSE_APP_DATES](response) {
    this.closeAppDatesResponse = response;
  }

  @Mutation
  [Mutations.SET_APPOINTMENTS_HISTORY](response) {
    this.slotsHistory = response;
  }



  @Action
  [Actions.UPDATE_SLOT](slot) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.patch("slots/" + slot.id, slot) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SLOT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_SLOT_LOG](slot) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("slot-logs", slot) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SLOT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_SLOT_LOG](log) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.patch("slot-logs/"+log.id, log) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SLOT, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_APP_DATES](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("organisations/set-date", payload) // 🟠
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APP_DATES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_APP_DATES](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("organisations/update-date", payload) // 🟠
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CLOSE_APP_DATES, data);
          console.log(data)
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }
  @Action
  [Actions.GET_ORG_APP_DATE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("organisations/get-app-date", payload) // 🟠
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORG_APP_DATE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PRE_SIGN_UP](value) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("pre-signup", value) // ✅
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          if(response){
            this.context.commit(Mutations.SET_ERROR, [response?.data?.message]);
            reject();
          }else{
            resolve();
          }
        });
    });
  }

  @Action
  [Actions.GET_APPOINTMENTS_HISTORY](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("slots/get-appointments-history-by-user-id/" + id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPOINTMENTS_HISTORY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }



}
