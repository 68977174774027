import {createI18n} from "vue-i18n/index";

const user = {
  en: {
    user: {
      fields: {
        name: "Name",
        username: "Kata Nama",
        phone: "Phone",
        email: "Email",
        gender: "Gender",
        birthdate: "Birthday",
      },
      header: "Profile",
    }
  },
  ms: {
    user: {
      fields: {
        name: "Nama"
      },
      header: "Profil",
    }
  }
};


export default user;
