import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/admin/Index.vue"),
        children: [
          {
            path: "users/",
            name: "admin-users",
            component: () => import("@/views/admin/Users.vue"),
          },
          {
            path: "user/:user_id",
            name: "admin-user",
            component: () => import("@/views/admin/User.vue"),
          },
          {
            path: "organisations/",
            name: "admin-organisations",
            component: () => import("@/views/admin/Organisations.vue"),
          },
          {
            path: "organisations/add",
            name: "admin-organisation-add",
            component: () => import("@/views/admin/Organisation.vue"),
          },
          {
            path: "organisation/:organisation_id",
            name: "admin-organisation",
            component: () => import("@/views/admin/Organisation.vue"),
          },
          {
            path: "tax-settings/",
            name: "admin-tax-settings",
            component: () => import("@/views/admin/TaxSettings.vue"),
          },
        ],
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/customers/Customers.vue"),
        children: [
          {
            path: "cbs/assignment/",
            name: "customers-assignment",
            component: () => import("@/views/customers/cbs/Assignment.vue"),
          },
          {
            path: "cbs/",
            name: "customers-cbs",
            component: () => import("@/views/customers/cbs/All.vue"),
          },
          {
            path: "cbs/urus",
            name: "customers-cbs-urus",
            component: () => import("@/views/customers/cbs/URUS.vue"),
          },
          {
            path: "cbs/first",
            name: "customers-cbs-first",
            component: () => import("@/views/customers/cbs/FIRST.vue"),
          },
          {
            path: "details/:id",
            name: "customers-details",
            component: () => import("@/views/customers/Details.vue"),
            children: [
              {
                path: "overview",
                name: "customers-overview",
                component: () => import("@/views/customers/Overview.vue"),
              },
              {
                path: "profile",
                name: "customers-profile",
                component: () => import("@/views/customers/Profile.vue"),
              },
              {
                path: "projects",
                name: "customer-projects",
                component: () => import("@/views/customers/Projects.vue"),
              },
              {
                path: "campaigns",
                name: "customer-campaigns",
                component: () => import("@/views/customers/Campaigns.vue"),
              },
              {
                path: "documents",
                name: "customer-documents",
                component: () => import("@/views/customers/Documents.vue"),
              },
              {
                path: "appointments",
                name: "customer-appointments",
                component: () => import("@/views/customers/Appointments.vue"),
              },
              {
                path: "appointment/:appointment_id",
                name: "customer-appointment",
                component: () =>
                  import("@/views/customers/appointments/Appointment.vue"),
              },
              {
                path: "connections",
                name: "customer-connections",
                component: () => import("@/views/customers/Connections.vue"),
              },
              {
                path: "activity/:appointment_id?",
                name: "customer-activity",
                component: () => import("@/views/customers/Activity.vue"),
              },
              {
                path: "financial-statements/sme",
                name: "customer-statements-sme",
                props: true,
                component: () =>
                  import("@/views/customers/FinancialStatements.vue"),
                children: [
                  {
                    path: "list",
                    name: "financial-statements-sme-list",
                    props: true,
                    component: () =>
                      import(
                        "@/views/customers/financial-statements-sme/List.vue"
                      ),
                  },
                ],
              },
              {
                path: "financial-statements/hh",
                name: "customer-statements-hh",
                props: true,
                component: () =>
                  import("@/views/customers/FinancialStatementsHH.vue"),
                children: [
                  {
                    path: "list",
                    name: "financial-statements-hh-list",
                    props: true,
                    component: () =>
                      import(
                        "@/views/customers/financial-statements-hh/List.vue"
                      ),
                  },
                ],
              },
              {
                path: "financial-scoring",
                name: "customer-financial-scoring",
                component: () =>
                  import("@/views/customers/FinancialScoring.vue"),
              },
              {
                path: "financial-scoring-hh",
                name: "customer-financial-scoring-hh",
                component: () =>
                  import("@/views/customers/FinancialScoringHH.vue"),
                children: [
                  {
                    path: "list",
                    name: "financial-scoring-list",
                    props: true,
                    component: () =>
                      import("@/views/financial-scoring/List.vue"),
                  },
                  {
                    path: "financial-scoring/:fin_id?",
                    name: "financial-scoring",
                    props: true,
                    component: () =>
                      import("@/views/financial-scoring/FinancialScoring.vue"),
                  },
                ],
              },
              {
                path: "dmp-plan",
                name: "customer-dmp-plan",
                component: () => import("@/views/customers/DMPPlan.vue"),
                children: [
                  {
                    path: "list",
                    name: "financial-resolution-list",
                    props: true,
                    component: () =>
                      import("@/views/financial-resolution/List.vue"),
                  },
                  {
                    path: "financial-resolution/:fin_id?",
                    name: "financial-resolution",
                    props: true,
                    component: () =>
                      import(
                        "@/views/financial-resolution/FinancialResolution.vue"
                      ),
                  },
                ],
              },
              {
                path: "sdrs-plan",
                name: "customer-sdrs-plan",
                component: () => import("@/views/customers/SDRSPlan.vue"),
              },
              {
                path: "sme-hd",
                name: "customer-sme-hd",
                component: () => import("@/views/customers/SMEHelpDesk.vue"),
                children: [
                  {
                    path: "list",
                    name: "sme-hd-list",
                    props: true,
                    component: () => import("@/views/sme-hd/List.vue"),
                  },
                  {
                    path: "plan/:ra_form_id?",
                    name: "sme-hd-plan",
                    props: true,
                    component: () =>
                      import(
                        "@/views/sme-hd/RepaymentAssistanceRequestForm.vue"
                      ),
                  },
                ],
              },
              {
                path: "reverse-mortgage",
                name: "customer-reverse-mortgage",
                component: () =>
                  import("@/views/customers/ReverseMortgage.vue"),
              },
              {
                path: "financial-planning",
                name: "customer-financial-planning",
                component: () =>
                  import("@/views/financial-planning/FinancialPlanning.vue"),
                  children: [
                    {
                      path: "will-planning",
                      name: "customer-will-planning",
                      props: true,
                      component: () => import("@/views/financial-planning/will-planning/WillPlanning.vue"),
                    },
                    {
                      path: "view-will-planning/:will_id?",
                      name: "view-customer-will-planning",
                      props: true,
                      component: () => import("@/views/financial-planning/will-planning/ViewWillPlanning.vue"),
                    },
                    {
                      path: "fact-finding-sheet",
                      name: "customer-fact-finding-sheet",
                      props: true,
                      component: () => import("@/views/financial-planning/fact-finding-sheet/FactFindingSheet.vue"),
                    },
                    {
                      path: "tax-planning",
                      name: "customer-tax-planning",
                      props: true,
                      component: () => import("@/views/financial-planning/tax-planning/TaxPlanning.vue"),
                    },
                    {
                      path: "risk-planning",
                      name: "customer-risk-planning",
                      props: true,
                      component: () => import("@/views/financial-planning/risk-planning/RiskPlanning.vue"),
                    },
                  ]
              },
              // {
              //   path: "myknp",
              //   name: "customer-myknp",
              //   component: () => import("@/views/customers/MyKNP.vue"),
              // },
              // {
              //   path: "pfp-report",
              //   name: "pfp-report",
              //   component: () => import("@/views/pfp-cover-letter/pfp-cover-letter.vue"),
              // },
            ],
          },
        ],
      },
      {
        path: "/pfp-report/:cstmr_id?",
        name: "pfp-report",
        component: () =>
          import("@/views/pfp-cover-letter/pfp-cover-letter.vue"),
      },
      {
        path: "/financial-calculators",
        name: "financial-calculators",
        component: () =>
          import("@/views/financial-calculators/Calculators.vue"),
        children: [
          {
            path: "list",
            name: "calculators-list",
            component: () =>
              import("@/views/financial-calculators/CalculatorsList.vue"),
          },
          {
            path: "hire-purchase/:id?",
            name: "hire-purchase",
            component: () =>
              import("@/views/financial-calculators/HirePurchase.vue"),
          },
          {
            path: "credit-card/:id?",
            name: "credit-card",
            component: () =>
              import("@/views/financial-calculators/CreditCard.vue"),
          },
          {
            path: "housing-loan/:id?",
            name: "housing-loan",
            component: () =>
              import("@/views/financial-calculators/HousingLoan.vue"),
          },
          {
            path: "personal-loan/:id?",
            name: "personal-loan",
            component: () =>
              import("@/views/financial-calculators/PersonalLoan.vue"),
          },
          {
            path: "investment-projections/:id?",
            name: "investment-projections",
            component: () =>
              import("@/views/financial-calculators/InvestmentProjections.vue"),
          },
          {
            path: "retirement-projections/:id?",
            name: "retirement-projections",
            component: () =>
              import("@/views/financial-calculators/RetirementProjections.vue"),
          },
        ],
      },
      {
        path: "/dmp-calculators",
        name: "dmp-calculators",
        component: () => import("@/views/dmp-calculators/DmpCalculators.vue"),
        children: [
          {
            path: "list",
            name: "dmp-calculators-list",
            component: () =>
              import("@/views/dmp-calculators/DmpCalculatorsList.vue"),
          },
          {
            path: "hire-purchase/:id?",
            name: "dmp-hire-purchase",
            component: () =>
              import("@/views/dmp-calculators/DmpHirePurchase.vue"),
          },
          {
            path: "credit-card/:id?",
            name: "dmp-credit-card",
            component: () =>
              import("@/views/dmp-calculators/DmpCreditCard.vue"),
          },
          {
            path: "housing-loan/:id?",
            name: "dmp-housing-loan",
            component: () =>
              import("@/views/dmp-calculators/DmpHousingLoan.vue"),
          },
          {
            path: "personal-loan/:id?",
            name: "dmp-personal-loan",
            component: () =>
              import("@/views/dmp-calculators/DmpPersonalLoan.vue"),
          },
        ],
      },
      {
        path: "/financial-statements/hh/:customer_id?",
        name: "financial-statements-hh",
        component: () => import("@/views/financial-statements/HH.vue"),
        children: [
          {
            path: "cash-flow/:fin_id?",
            name: "cash-flow-hh",
            component: () =>
              import("@/views/financial-statements/hh/CashFlow.vue"),
          },
          {
            path: "networth-statement/:fin_id?",
            name: "networth-hh",
            component: () =>
              import("@/views/financial-statements/hh/Networth.vue"),
          },
        ],
      },
      {
        path: "/financial-statements/sme/:customer_id?",
        name: "financial-statements-sme",
        component: () => import("@/views/financial-statements/SME.vue"),
        children: [
          {
            path: "sales/:fin_id?",
            name: "sales-sme",
            component: () =>
              import(
                "@/views/financial-statements/sme/income-statements/Sales.vue"
              ),
          },
          {
            path: "cost-of-sales/:fin_id?",
            name: "cost-of-sales-sme",
            component: () =>
              import(
                "@/views/financial-statements/sme/income-statements/CostOfSales.vue"
              ),
          },
          {
            path: "other-income-expenses/:fin_id?",
            name: "other-income-expenses-sme",
            component: () =>
              import(
                "@/views/financial-statements/sme/income-statements/OtherIncomeExpenses.vue"
              ),
          },
          {
            path: "tax-and-depreciation/:fin_id?",
            name: "tax-and-depreciation-sme",
            component: () =>
              import(
                "@/views/financial-statements/sme/income-statements/TaxDepreciation.vue"
              ),
          },
          {
            path: "cash-flow/:fin_id?",
            name: "cash-flow-sme",
            component: () =>
              import("@/views/financial-statements/sme/CashFlow.vue"),
          },
          {
            path: "profit-loss/:fin_id?",
            name: "profit-loss-sme",
            component: () =>
              import("@/views/financial-statements/sme/ProfitLoss.vue"),
          },
          {
            path: "balance-sheet/:fin_id?",
            name: "balance-sheet-sme",
            component: () =>
              import("@/views/financial-statements/sme/BalanceSheet.vue"),
          },
        ],
      },
      {
        path: "financial-scoring-hh-preview",
        name: "customer-financial-scoring-hh-preview",
        component: () =>
          import("@/views/financial-scoring/FinancialScoringPreview.vue"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "dashboard/financial-advisor",
        name: "financial-advisor-dashboard",
        component: () => import("@/views/DashboardFA.vue"),
      },
      {
        path: "dashboard/branch-head",
        name: "branch-head-dashboard",
        component: () => import("@/views/DashboardBH.vue"),
      },
      {
        path: "dashboard/head-of-department",
        name: "head-of-department-dashboard",
        component: () => import("@/views/DashboardHOD.vue"),
      },
      // {
      //   path: "/dashboard",
      //   name: "dashboard",
      //   component: () => import("@/views/Dashboard.vue"),
      //   children: [
      //     {
      //       path: "financial-advisor",
      //       name: "financial-advisor-dashboard",
      //       component: () => import("@/views/DashboardFA.vue"),
      //     },
      //     {
      //       path: "branch-head",
      //       name: "branch-head-dashboard",
      //       component: () => import("@/views/DashboardBH.vue"),
      //     },
      //     {
      //       path: "head-of-department",
      //       name: "head-of-department-dashboard",
      //       component: () => import("@/views/DashboardHOD.vue"),
      //     },
      //   ]
      // },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/form",
        name: "form",
        component: () => import("@/views/Form.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/crafted/users/profile.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/notifications/Notifications.vue"),
      },
      // {
      //   path: "/financial-statements/sme/cash-flow",
      //   name: "cash-flow",
      //   component: () => import("@/views/financial-statements/sme/CashFlow.vue"),
      // },
      // {
      //   path: "/financial-statements/sme/income-statements/sales",
      //   name: "sales",
      //   component: () => import("@/views/financial-statements/sme/income-statements/Sales.vue"),
      // },
      // {
      //   path: "/financial-statements/sme/income-statements/sales",
      //   name: "cost-of-sales",
      //   component: () => import("@/views/financial-statements/sme/income-statements/Sales.vue"),
      // },
      // {
      //     path: "/financial-statement/incomeStatement/sales",
      //     name: "sales",
      //     component: () => import("@/views/financialStatement/incomeStatement/sales.vue"),
      // },
      // {
      //     path: "/financial-statement/cash-flows",
      //     name: "cash-flows",
      //     component: () => import("@/views/financialStatement/CashFlows.vue"),
      // },
      // {
      //     path: "/financial-statement/stock-card/stock-card-detail",
      //     name: "StockCardDetail",
      //     component: () => import("@/views/financialStatement/stockCard/StockCardDetail.vue"),
      //     path: "/organisation/list",
      //     name: "pages.organisation.list",
      //     component: () => import("@/views/organisation/List.vue"),
      // },
      {
        path: "/organisation/list",
        name: "pages.organisation.list",
        component: () => import("@/views/organisation/List.vue"),
      },
      {
        path: "/organisation/edit/:id",
        name: "pages.organisation.add",
        component: () => import("@/views/organisation/Organisation.vue"),
      },
      {
        path: "/organisation/add",
        name: "AddcharAccounts",
        component: () => import("@/views/organisation/Organisation.vue"),
      },
      {
        path: "/financial-planning",
        name: "financial-planning",
        component: () => import("@/views/financial-planning/FinancialPlanning.vue"),
        children: [
          {
            path: "retirement-planning",
            name: "retirement-planning",
            component: () =>
              import(
                "@/views/financial-planning/retirement-planning/RetirementPlanning.vue"
              ),
            children: [
              {
                path: "about-you",
                name: "retirement-planning-about-you",
                component: () =>
                  import(
                    "@/views/financial-planning/retirement-planning/AboutYou.vue"
                  ),
              },
              {
                path: "current-retirement-plan",
                name: "retirement-planning-current-retirement-plan",
                component: () =>
                  import(
                    "@/views/financial-planning/retirement-planning/CurrentRetirementPlan.vue"
                  ),
              },
            ],
          },
          {
            path: "will-planning",
            name: "willPlanning",
            component: () =>
              import(
                "@/views/financial-planning/will-planning/WillPlanning.vue"
              ),
          },
          {
            path: "fact-finding-sheet",
            name: "factFindingSheet",
            component: () =>
              import(
                "@/views/financial-planning/fact-finding-sheet/FactFindingSheet.vue"
              ),
          },
          {
            path: "tax-planning",
            name: "taxPlanning",
            component: () =>
              import(
                "@/views/financial-planning/tax-planning/TaxPlanning.vue"
              ),
          },
          {
            path: "risk-planning",
            name: "riskPlanning",
            component: () =>
              import(
                "@/views/financial-planning/risk-planning/RiskPlanning.vue"
              ),
          },
          {
            path: "investment-planning",
            name: "investment-planning",
            component: () =>
              import(
                "@/views/financial-planning/investment-planning/InvestmentPlanning.vue"
              ),
            children: [
              {
                path: "end-amount",
                name: "investment-planning-end-amount",
                component: () =>
                  import(
                    "@/views/financial-planning/investment-planning/EndAmount.vue"
                  ),
              },
              {
                path: "additional-contribution",
                name: "investment-planning-additional-contribution",
                component: () =>
                  import(
                    "@/views/financial-planning/investment-planning/AdditionalContribution.vue"
                  ),
              },
              {
                path: "return-rate",
                name: "investment-planning-return-rate",
                component: () =>
                  import(
                    "@/views/financial-planning/investment-planning/ReturnRate.vue"
                  ),
              },
              {
                path: "starting-amount",
                name: "investment-planning-starting-amount",
                component: () =>
                  import(
                    "@/views/financial-planning/investment-planning/StartingAmount.vue"
                  ),
              },
              {
                path: "investment-length",
                name: "investment-planning-investment-length",
                component: () =>
                  import(
                    "@/views/financial-planning/investment-planning/InvestmentLength.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/financial-statements",
        name: "financial-statements",
        component: () => import("@/views/financial-statements/HH.vue"),
        children: [
          {
            path: "hh/cash-flow",
            name: "cash-flow-hh-fa",
            component: () =>
              import("@/views/financial-statements/hh/CashFlow.vue"),
          },
          {
            path: "hh/net-worth-statement",
            name: "networth-hh-fa",
            component: () =>
              import("@/views/financial-statements/hh/Networth.vue"),
          },
          {
            path: "daily-cash-flow",
            name: "daily-cash-flow",
            component: () =>
              import("@/views/statements/cash-flow/DailyCashFlow.vue"),
          },
          {
            path: "statement-cash-flow",
            name: "statement-cash-flow",
            component: () =>
              import("@/views/statements/cash-flow/StatementCashFlow.vue"),
          },
          {
            path: "income-statement/sales",
            name: "income-statement/sales",
            component: () =>
              import("@/views/statements/income-statement/Sales.vue"),
          },
          {
            path: "income-statement/cost-of-sales",
            name: "income-statement/cost-of-sales",
            component: () =>
              import("@/views/statements/income-statement/CostSales.vue"),
          },
          {
            path: "income-statement/other-income-expenses",
            name: "income-statement/other-income-expenses",
            component: () =>
              import(
                "@/views/statements/income-statement/OtherIncomeExpenses.vue"
              ),
          },
          {
            path: "income-statement/tax-depreciation",
            name: "income-statement/tax-depreciation",
            component: () =>
              import("@/views/statements/income-statement/TaxDepreciation.vue"),
          },
          {
            path: "income-statement/profit-loss",
            name: "income-statement/profit-loss",
            component: () =>
              import("@/views/statements/income-statement/ProfitLoss.vue"),
          },
          {
            path: "stock-card/summary-stock",
            name: "stock-card/summary-stock",
            component: () =>
              import("@/views/statements/stock-card/SummaryStock.vue"),
          },
          {
            path: "stock-card/stock-card-detail",
            name: "stock-card/stock-card-detail",
            component: () =>
              import("@/views/statements/stock-card/StockCardDetail.vue"),
          },
          {
            path: "balance-sheet/balance-sheet",
            name: "balance-sheet/balance-sheet",
            component: () =>
              import("@/views/statements/balance-sheet/BalanceSheet.vue"),
          },
          {
            path: "financial-health-ratio/data-from-pl-or-bs",
            name: "financial-health-ratio/data-from-pl-or-bs",
            component: () =>
              import(
                "@/views/statements/financial-health-ratio/DataFromPLorBS.vue"
              ),
          },
          {
            path: "financial-health-ratio/financial-health-ratios",
            name: "financial-health-ratio/financial-health-ratios",
            component: () =>
              import(
                "@/views/statements/financial-health-ratio/HealthRatios.vue"
              ),
          },
          {
            path: "financial-health-ratio/data-from-pl-or-bs-SME",
            name: "financial-health-ratio/data-from-pl-or-bs-SME",
            component: () =>
              import(
                "@/views/statements/financial-health-ratio/DataFromPLorBSSME.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/print",
    component: () => import("@/layout/Print.vue"),
    children: [
      {
        path: "/print/customers/details/:id/sme-hd/view/:ra_form_id",
        name: "print-ra-form",
        component: () => import("@/views/sme-hd/View.vue"),
      },
      {
        path: "/print/admin/organisations/qrcode/:organisation_id",
        name: "admin-organisation-qrcode",
        component: () => import("@/views/admin/organisation/QRCode.vue"),
      },
    ],
  },
  // {
  //   path: "/financial-statements",
  //   name: "financial-statements",
  //   component: () => import("@/layout/Layout.vue"),
  //   children: [
  //     {
  //       path: "daily-cash-flow",
  //       name: "daily-cash-flow",
  //       component: () => import("@/views/financialStatement/cash-flow/DailyCashFlow.vue"),
  //     },
  //     {
  //       path: "statement-cash-flow",
  //       name: "statement-cash-flow",
  //       component: () => import("@/views/financialStatement/cash-flow/StatementCashFlow.vue"),
  //     },
  //     {
  //       path: "income-statement/sales",
  //       name: "income-statement/sales",
  //       component: () => import("@/views/financialStatement/income-statement/Sales.vue"),
  //     },
  //     {
  //       path: "income-statement/cost-of-sales",
  //       name: "income-statement/cost-of-sales",
  //       component: () => import("@/views/financialStatement/income-statement/CostSales.vue"),
  //     },
  //     {
  //       path: "income-statement/other-income-expenses",
  //       name: "income-statement/other-income-expenses",
  //       component: () => import("@/views/financialStatement/income-statement/OtherIncomeExpenses.vue"),
  //     },
  //     {
  //       path: "income-statement/tax-depreciation",
  //       name: "income-statement/tax-depreciation",
  //       component: () => import("@/views/financialStatement/income-statement/TaxDepreciation.vue"),
  //     },
  //     {
  //       path: "income-statement/profit-loss",
  //       name: "income-statement/profit-loss",
  //       component: () => import("@/views/financialStatement/income-statement/ProfitLoss.vue"),
  //     },
  //     {
  //       path: "stock-card/summary-stock",
  //       name: "stock-card/summary-stock",
  //       component: () => import("@/views/financialStatement/stock-card/SummaryStock.vue"),
  //     },
  //     {
  //       path: "stock-card/stock-card-detail",
  //       name: "stock-card/stock-card-detail",
  //       component: () => import("@/views/financialStatement/stock-card/StockCardDetail.vue"),
  //     },
  //     {
  //       path: "balance-sheet/balance-sheet",
  //       name: "balance-sheet/balance-sheet",
  //       component: () => import("@/views/financialStatement/balance-sheet/BalanceSheet.vue"),
  //     },
  //     // {
  //     //     path: "financial-health-ratio/data-from-pl-or-bs",
  //     //     name: "financial-health-ratio/data-from-pl-or-bs",
  //     //     component: () => import("@/views/financialStatement/financial-health-ratio/DataFromPLorBS.vue"),
  //     // },
  //     // {
  //     //     path: "financial-health-ratio/financial-health-ratios",
  //     //     name: "financial-health-ratio/financial-health-ratios",
  //     //     component: () => import("@/views/financialStatement/financial-health-ratio/HealthRatios.vue"),
  //     // },
  //     // {
  //     //     path: "cash-flow/household-cash-flow",
  //     //     name: "cash-flow/household-cash-flow",
  //     //     component: () => import("@/views/financialStatement/cash-flow/HouseholdCashFlow.vue"),
  //     // },
  //     // {
  //     //     path: "financial-health-ratio/data-from-pl-or-bs-SME",
  //     //     name: "financial-health-ratio/data-from-pl-or-bs-SME",
  //     //     component: () => import("@/views/financialStatement/financial-health-ratio/DataFromPLorBSSME.vue"),
  //     // },
  //     // {
  //     //     path: "net-worth-statement",
  //     //     name: "net-worth-statement",
  //     //     component: () => import("@/views/financialStatement/cash-flow/NetWorthStatement.vue"),
  //     // },

  //   ],
  // },
  {
    path: "/financial-skills",
    name: "financial-skills",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "modules",
        name: "FinancialSkillsModules",
        component: () =>
          import("@/views/financial-skills/FinancialSkillsModules.vue"),
      },
      {
        path: "course-lists",
        name: "FinancialSkillsCourseLists",
        component: () =>
          import("@/views/financial-skills/FinancialSkillsCourseLists.vue"),
      },
    ],
  },
  {
    path: "/pfp-cover-letter",
    name: "pfp-cover-letter",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/pfp-cover-letter",
        name: "pfp-cover-letter",
        component: () =>
          import("@/views/pfp-cover-letter/pfp-cover-letter.vue"),
      },
    ],
  },
  {
    path: "/financial-scoring-hh",
    name: "financial-scoring-hh",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/financial-scoring-hh",
        name: "financial-scoring-hh",
        component: () => import("@/views/customers/FinancialScoringHH.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/crafted/users/profile.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.CSRF);
  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
