import ApiService from "@/core/services/ApiService";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class DMPModule extends VuexModule {
    errors = {};
    dmpResolutions = {};

    get getDMPResolutions() {
        return this.dmpResolutions;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
      this.errors = error;
    }

    @Mutation
    [Mutations.SET_DMP_RESOLUTIONS](data) {
        this.dmpResolutions = data
    }

    @Action
    [Actions.GET_DMP_RESOLUTIONS](user_id) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.get("dmp-resolutions/index-by-user/" + user_id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_DMP_RESOLUTIONS, data);
                resolve();
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                reject();
            });
        });
    }
}