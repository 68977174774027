enum Actions {
  // action types
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  ADD_CLASSNAME = "addClassName",
  ADMIN_ASSIGN_OFFICE = "adminAssignOffice",
  ADMIN_DELETE_ASSIGNED_AO = "adminDeleteAssignedAo",
  ADMIN_GET_USER_FAS = "getUserFas",
  ADMIN_UPDATE_ORGANISATION = "adminUpdateOrganisation",
  ADMIN_UPDATE_USER_FAS = "adminUpdateUserFas",
  CSRF = "getCsrf",
  DELETE_RA_FORM_DOCUMENT = "deleteRaFormDocument",
  FORGOT_PASSWORD = "forgotPassword",
  GET_ACCESS_TOKEN = "getAccessToken",
  GET_ADMIN_USER = "getAdminUser",
  GET_ADVISOR = "getAdvisor",
  GET_ADVISORY_OFFICES = "getAdvisoryOffices",
  GET_ALL_NOTIFICATIONS = "getAllNotifications",
  GET_APPOINTMENT = "getAppointment",
  GET_APPOINTMENTS = "getAppointments",
  GET_APPOINTMENTS_HISTORY = "getAppointmentsHistory",
  GET_ASSIGNMENTS = "getAssignments",
  GET_AUTH_USER = "getAuthUser",
  GET_BRANCHES = "getBranches",
  GET_CALCULATOR = "getCalculator",
  GET_CALCULATORS = "getCalculators",
  GET_CCRIS = "getCcris",
  GET_CITIES = "getCities",
  GET_COURSE_LIST = "getCourseList",
  GET_COURSE_LISTS = "getCourseLists",
  GET_CREDITORS = "getCreditors",
  GET_CREDITOR_PROFILING = "getCreditorProfiling",
  GET_CUSTOMER = "getCustomer",
  GET_CUSTOMERS = "getCustomers",
  GET_CUSTOMER_BY_NRIC = "getCustomerByNric",
  GET_DMP_RESOLUTIONS = "getDMPResoultions",
  GET_DOC_BY_USER_TAG = "getDocByUserTag",
  GET_DOC_PARSER_CCRIS = "getDocParserCcris",
  GET_ENROLLMENT_DETAILS = "getEnrollmentDetails",
  GET_FACILITIES = "getFaciities",
  GET_FA_LIST = "getFaList",
  GET_FA_LIST_BY_ORG_ID = "getFaListByOrgId",
  GET_FA_OFFICES = "getFaOffices",
  GET_FINANCIAL_PLANNING = "getFinancialPlanning",
  GET_FINANCIAL_RESOLUTION = "getFinancialResolution",
  GET_FINANCIAL_RESOLUTIONS = "getFinancialResolutions",
  GET_FINANCIAL_STATEMENT = "getFinancialStatement",
  GET_FINANCIAL_STATEMENTS = "getFinancialStatements",
  GET_MYKNP_CERTIFICATE = "getMYKNPCertificate",
  GET_OCR = "getOcr",
  GET_OQFS = "getOqfs",
  GET_ORGANISATION = "getOrganisation",
  GET_ORG_APP_DATE = "getOrgAppDate",
  GET_PFP = "getPfp",
  GET_PFPS = "getPfps",
  GET_PFP_REPORT = "getPfpReport",
  GET_PROPERTY = "getProperty",
  GET_RACES = "getRaces",
  GET_RA_FORM = "getRaForm",
  GET_RA_FORMS = "getRaForms",
  GET_REVERSE_MORTGAGE = "getReverseMortage",
  GET_RISK_PLANNING = "getRiskPlanning",
  GET_RISK_PLANNINGS = "getRiskPlannings",
  GET_RM_CERTIFICATE = "getRMCertificate",
  GET_SLOT_SET = "getSlotSet",
  GET_SLOT_SETS = "getSlotSets",
  GET_STATES = "getStates",
  GET_TAX_LIMITS = "getTaxLimits",
  GET_TAX_PLANNING = "getTaxPlanning",
  GET_TAX_PLANNINGS = "getTaxPlannings",
  GET_TAX_SETTINGS = "getTaxSettings",
  GET_UNREAD_NOTIFICATIONS = "getUnreadNotifications",
  GET_USERS = "getUsers",
  GET_USER_BASIC_PROFILE = "getUserBasicProfile",
  GET_USER_FAMILY_PROFILE = "getUserFamilyProfile",
  GET_USER_FFS_PROFILE = "getUserFFSProfile",
  GET_USER_RAW = "getUserRaw",
  GET_USER_WILL_PROFILE = "getUserWillProfile",
  GET_WILL_PLANNING = "getWillPlanning",
  GET_WILL_PLANNINGS = "getWillPlannings",
  LOGIN = "login",
  LOGOUT = "logout",
  PRE_REGISTER_CUSTOMER = "preRegisterCustomer",
  PRE_STORE_APPOINTMENT = "preStoreAppointment",
  REGISTER = "register",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  STORE_APPOINTMENT = "storeAppointment",
  STORE_APP_DATES = "storeAppDates",
  STORE_ASSIGNMENT = "storeAssignment",
  STORE_CALCULATION = "storeCalculation",
  STORE_CALCULATOR = "storeCalculator",
  STORE_CUSTOMER_RAW = "storeCustomerRaw",
  STORE_DOC = "storeDoc",
  STORE_FINANCIAL_PLANNING = "storeFinancialPlanning",
  STORE_FINANCIAL_RESOLUTION = "storeFinancialResolution",
  STORE_FINANCIAL_STATEMENT = "storeFinancialStatement",
  STORE_MYKNP_CERTIFICATE = "storeMYKNPCertificate",
  STORE_OQF = "storeOqf",
  STORE_ORGANISATION = "storeOrganisation",
  STORE_PFP = "storePfp",
  STORE_PRE_SIGN_UP = "storePreSignUp",
  STORE_PROFILE = "storeProfile",
  STORE_RAW = "storeRaw",
  STORE_RAW_APPOINTMENT = "storeRawAppointment",
  STORE_RA_FORM = "storeRaForm",
  STORE_REVERSE_MORTGAGE_CHECKLIST = "storeReverseMortgageChecklist",
  STORE_REVERSE_MORTGAGE_SSB_ADVISOR_DISCLAIMER = "storeReverseMortgageSSBAdvisorDisclaimer",
  STORE_RISK_PLANNING = "storeRiskPlanning",
  STORE_SLOT_LOG = "storeSlotLog",
  STORE_TAX_PLANNING = "storeTaxPlanning",
  STORE_TAX_SETTINGS = "storeTaxSettings",
  STORE_WILL_PLANNING = "storeWillPlanning",
  TWOFA_LOGIN = "twoFaLogin",
  UPDATE_APP_DATES = "updateAppDates",
  UPDATE_CALCULATOR = "updateCalculator",
  UPDATE_CUSTOMER = "updateCustomer",
  UPDATE_CUSTOMER_RAW = "updateCustomerRaw",
  UPDATE_FINANCIAL_RESOLUTION = "updateFinancialResolution",
  UPDATE_FINANCIAL_STATEMENT = "updateFinancialStatement",
  UPDATE_NOTIFICAITON_AS_READ = "updateNotificationAsRead",
  UPDATE_ORGANISATION = "updateOrganisation",
  UPDATE_PROFILE = "updateProfile",
  UPDATE_RA_FORM = "updateRaForm",
  UPDATE_SLOT = "updateSlot",
  UPDATE_SLOT_LOG = "updateSlotLog",
  UPDATE_USER = "updateUser",
  UPLOAD_CCRIS = "uploadCcris",
  UPLOAD_CCRIS2 = "uploadCcris2",
  UPLOAD_RA_FORM = "uploadRaForm",
  VERIFY_AUTH = "verifyAuth",
}

enum Mutations {
  // mutation types
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  PURGE_AUTH = "logOut",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  SET_ACCESS_TOKEN = "setAccessToken",
  SET_ADMIN_USER = "setAdminUser",
  SET_ADVISOR = "setAdvisor",
  SET_ADVISORY_OFFICES = "setAdvisoryOffices",
  SET_APPOINTMENT = "setAppointment",
  SET_APPOINTMENTS = "setAppointments",
  SET_APPOINTMENTS_HISTORY = "setAppointmentsHistory",
  SET_APP_DATES = "setAppDates",
  SET_ASSIGNMENT = "setAssignment",
  SET_ASSIGNMENTS = "setAssignments",
  SET_AUTH = "setAuth",
  SET_AUTH_USER = "setAuthUser",
  SET_BRANCHES = "setBranches",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_CALCULATION = "setCalculation",
  SET_CALCULATOR = "setCalculator",
  SET_CALCULATORS = "setCalculators",
  SET_CCRIS = "setCcris",
  SET_CITIES = "setCities",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  SET_CLOSE_APP_DATES = "setCloseAppDates",
  SET_COURSE_LIST = "setCourseList",
  SET_COURSE_LISTS = "setCourseLists",
  SET_CREDITORS = "setCreditors",
  SET_CREDITOR_PROFILING = "setCreditorProfiling",
  SET_CUSTOMER = "setCustomer",
  SET_CUSTOMERS = "setCustomers",
  SET_CUSTOMER_BASIC_PROFILE = "setCustomerBasicProfile",
  SET_CUSTOMER_FAMILY_PROFILE = "setCustomerFamilyProfile",
  SET_CUSTOMER_FFS_PROFILE = "setCustomerFFSProfile",
  SET_CUSTOMER_WILL_PROFILE = "setCustomerWillProfile",
  SET_DMP_RESOLUTIONS = "setDMPResolutions",
  SET_ENROLLMENT_DETAILS = "setEnrollmentDetails",
  SET_ERROR = "setError",
  SET_FACILITIES = "setFaciities",
  SET_FA_LIST = "setFaList",
  SET_FA_LIST_BY_ORG_ID = "setFaListByOrgId",
  SET_FA_OFFICES = "setFaOffices",
  SET_FINANCIAL_PLANNING = "setFinancialPlanning",
  SET_FINANCIAL_RESOLUTION = "setFinancialResolution",
  SET_FINANCIAL_RESOLUTIONS = "setFinancialResolutions",
  SET_FINANCIAL_STATEMENT = "setFinancialStatement",
  SET_FINANCIAL_STATEMENTS = "setFinancialStatements",
  SET_GLOBAL_DATA = "setGlobalData",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  SET_MEDIA = "setMedia",
  SET_MYKNP_CERTIFICATE = "setMYKNPCertificate",
  SET_MYKNP_CHECKLIST = "setMyKNPChecklist",
  SET_MYKNP_CUSTOMER_FEEDBACK = "setMyKNPCustomerFeedback",
  SET_MYKNP_PRE_ASSESSMENT_INFO = "setMyKNPPreAssessmentInfo",
  SET_MYKNP_PROPERTY_INFO = "setMyKNPPropertyInfo",
  SET_NOTIFICATION = "setNotification",
  SET_NOTIFICATIONS = "setNotifications",
  SET_OQF = "setOqf",
  SET_OQFS = "setOqfs",
  SET_ORGANISATION = "setOrganisation",
  SET_ORG_APP_DATE = "setOrgAppDate",
  SET_PASSWORD = "setPassword",
  SET_PFP = "setPfp",
  SET_PFPS = "setPfps",
  SET_PFP_REPORT = "setPfpReport",
  SET_PRE_SIGN_UP = "setPreSignUp",
  SET_PROFILE = "setProfile",
  SET_PROPERTY = "setProperty",
  SET_RACES = "setRaces",
  SET_RAW = "setRaw",
  SET_RAW_APPOINTMENT_RESPONSE = "setRawAppointmentResponse",
  SET_RA_FORM = "setRaForm",
  SET_RA_FORMS = "setRaForms",
  SET_RESPONSE_RA_FORM = "setResponseRaForm",
  SET_RESPONSE_UPDATE_ORG = "setResponseUpdateOrg",
  SET_REVERSE_MORTGAGE = "setReverseMortgage",
  SET_RISK_PLANNING = "setRiskPlanning",
  SET_RM_CERTIFICATE = "setRMCertificate",
  SET_SLOT = "setSlot",
  SET_SLOT_SET = "setSlotSet",
  SET_SLOT_SETS = "setSlotSets",
  SET_STATES = "setStates",
  SET_TAX_LIMITS = "setTaxLimits",
  SET_TAX_PLANNING = "setTaxPlanning",
  SET_TAX_PLANNINGS = "setTaxPlannings",
  SET_TAX_SETTINGS = "setTaxSettings",
  SET_UNREAD_NOTIFICATIONS = "setUnreadNotifications",
  SET_USER = "setUser",
  SET_USERS = "setUsers",
  SET_USER_FAS = "setUserFas",
  SET_USER_RAW = "setUserRaw",
  SET_WILL_PLANNING = "setWillPlanning",
  SET_WILL_PLANNINGS = "setWillPlannings",
}

export { Actions, Mutations };
