import {createI18n} from "vue-i18n/index";

const general = {
  en: {
    pages: {
      organisation: {
        title: "Organisations",
        list: "List of Branch & Office",
        add: "Add Organisation",
        edit_branch: "Edit Branch",
        edit_office: "Edit Office",

      }
    },
    flash: {
      success: {
        title: 'Alert!',
        message: 'Successfully updated'
      },
      error: {
        title: 'Alert!',
        message: 'Sorry plesae try again'
      },
    },
    buttons: {
      cancel: 'Cancel',
      please_wait: 'Please Wait',
      submit: 'Submit'
    },
    table: {
      language: {
        emptyTable: "No data available in table",
        info: "Showing _START_ to _END_ of _TOTAL_ entries",
        infoEmpty: "Showing 0 to 0 of 0 entries",
        infoFiltered: "(filtered from _MAX_ total entries)",
        infoThousands: ",",
        lengthMenu: "Show _MENU_ entries",
        loadingRecords: "Loading...",
        processing: "Processing...",
        search: "Search:",
        zeroRecords: "No matching records found",
        thousands: ",",
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous"
        },
        aria: {
          sortAscending: ": activate to sort column ascending",
          sortDescending: ": activate to sort column descending"
        },
      }
    }
  },
  ms: {
    pages: {
      organisation: {
        title: "Organisasi",
        list: "Senarai Cawangan dan Pejabat",
        add: "Tambah Organisasi",
        edit_branch: "Kemaskini Cawangan",
        edit_office: "Kemaskini Pejabat",

      }
    },
    flash: {
      success: {
        title: '',
        message: ''
      },
      error: {
        title: '',
        message: ''
      },
    },
    buttons: {
      cancel: 'Cancel',
      please_wait: 'Please Wait',
      submit: 'Submit'
    },
    table: {
      language: {
        emptyTable: "Tiada data",
        info: "Paparan dari _START_ hingga _END_ dari _TOTAL_ rekod",
        infoEmpty: "Paparan 0 hingga 0 dari 0 rekod",
        infoFiltered: "(Ditapis dari jumlah _MAX_ rekod)",
        infoThousands: ",",
        lengthMenu: "Papar _MENU_ rekod",
        loadingRecords: "Diproses...",
        processing: "Sedang diproses...",
        search: "Carian:",
        zeroRecords: "Tiada padanan rekod yang dijumpai.",
        thousands: ",",
        paginate: {
          first: "Pertama",
          previous: "Sebelum",
          next: "Seterusnya",
          last: "Akhir"
        },
        aria: {
          sortAscending: ": diaktifkan kepada susunan lajur menaik",
          sortDescending: ": diaktifkan kepada susunan lajur menurun"
        }
      }
    }
  },
};

export default general;
