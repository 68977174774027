import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CourseList {
    label: string,
    category: string,
    code: string
}

@Module
export default class FinancialSkillsModule extends VuexModule {
    errors = {};
    courseLists = [] as CourseList[];
    courseList = {} as CourseList;

    get getCourseListsData() {
        return this.courseLists;
    }

    get getCourseListData() {
        return this.courseList;
    }
    /**
   * Get current user object
   * @returns User
   */
    get getCurrentCourseListData(): CourseList {
        return this.getCourseListData;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_COURSE_LISTS](data) {
        this.courseLists = data
    }

    @Mutation
    [Mutations.SET_COURSE_LIST](data) {
        this.courseList = data
    }

    @Action
    [Actions.GET_COURSE_LISTS]() {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.get("power-portal/course-list")
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_COURSE_LISTS, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(Mutations.SET_ERROR, [response.data.message]);
                    reject();
                });
        });
    }
}