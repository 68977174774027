
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
  data() {
    const timeoutDuration = 15 * 60 * 1000; //Set to 15 Mnts
    return {
      store: useStore(),
      router: useRouter(),
      idleTimeout: null,
      timeoutDuration,
    };
  },
  mounted() {
    //check if current user is authenticated
    if (!this.store.getters.isUserAuthenticated) {
      this.router.push({ name: "sign-in" });
    }
    this.startIdleTimeout();
    this.resetIdleTimeout();

    // window.onbeforeunload = function () {
    //   this.logout();
    // };
  },
  methods: {
    updateLastActivity() {
      const currentTime = new Date().getTime();
      localStorage.setItem("lastActivity", currentTime.toString());
    },
    beforeUnmount() {
      // Clean up event listeners when the component is destroyed
      window.removeEventListener("mousemove", this.updateLastActivity);
      window.removeEventListener("keydown", this.updateLastActivity);
      window.removeEventListener("click", this.updateLastActivity);
    },

    startIdleTimeout() {
      setInterval(() => {
        const lastActivity = parseInt(localStorage.getItem("lastActivity"));
        const currentTime = new Date().getTime();
        if (currentTime - lastActivity > this.timeoutDuration) {
          // Perform logout or other action when no activity for more than 15 minutes
          this.logout();
        }
      }, 15000); // Check for inactivity every 15 seconds
    },
    resetIdleTimeout() {
      // Reset the idle timeout whenever user activity is detected
      window.addEventListener("mousemove", this.updateLastActivity);
      window.addEventListener("keydown", this.updateLastActivity);
      window.addEventListener("click", this.updateLastActivity);
    },
    logout() {
      this.store
        .dispatch(Actions.LOGOUT)
        .then(() => this.router.push({ name: "sign-in" }));
      // Make a request to the backend to logout the user
      // using Laravel Passport's token management
    },
  },
});
