import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class AdminModule extends VuexModule {
  errors = {};
  taxSettings = [] as any;


  get getTaxSettingsData() {
    return this.taxSettings;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_TAX_SETTINGS](data) {
    this.taxSettings = data
  }


  @Action
  [Actions.GET_TAX_SETTINGS]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/tax-settings") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_SETTINGS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_TAX_SETTINGS](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/tax-settings", data) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TAX_SETTINGS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  // @Action
  // [Actions.UPDATE_OQF](oqf) {
  //   ApiService.setFormDataHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.patch("oqf/" + oqf.id, oqf)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_OQF, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, [response.data.message]);
  //         reject();
  //       });
  //   });
  // }
}
