import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CbsLookup {
  id: number,
  type: string,
  MasterDataMaintenanceID: number,
  ParameterName: string,
  ParameterValue: string,
}


@Module
export default class CbsLookupModule extends VuexModule {
  creditors = {} as CbsLookup;
  facilities = {} as CbsLookup;
  cities = {} as CbsLookup;
  states = {} as CbsLookup;
  races = {} as CbsLookup;

  get getCreditorsData() {
    return this.creditors;
  }

  get getFacilitiesData() {
    return this.facilities;
  }

  get getCitiesData() {
    return this.cities;
  }

  get getStatesData() {
    return this.states;
  }

  get getRacesData() {
    return this.races;
  }

  @Mutation
  [Mutations.SET_CREDITORS](data) {
    this.creditors = data
  }

  @Mutation
  [Mutations.SET_FACILITIES](data) {
    this.facilities = data
  }

  @Mutation
  [Mutations.SET_CITIES](data) {
    this.cities = data
  }

  @Mutation
  [Mutations.SET_STATES](data) {
    this.states = data
  }

  @Mutation
  [Mutations.SET_RACES](data) {
    this.races = data
  }

  @Action
  [Actions.GET_CREDITORS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("cbs-lookups/index-by-type/bank-name") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CREDITORS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_FACILITIES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("cbs-lookups/index-by-type/loan-type") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FACILITIES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CITIES](state_id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("locations/index-by-state/" + state_id) // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CITIES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_STATES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("locations/index-state") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RACES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("cbs-lookups/index-by-type/race") // ✅
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RACES, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

}
